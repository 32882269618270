import React from 'react';


function NoMatch() {
  
  
    return (
         
  <><div style={{fontSize:'100px'}}>404</div></>
    
    );
}


export default NoMatch;